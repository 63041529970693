@import "./mediaquery";
@import "./variables";

.settings {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 1em;
  position: relative;

  .list {
    background-color: white;
    border-radius: 8px;
    padding: 1em;
    min-height: 100%;
    height: fit-content;

    h3 {
      color: $primarylight;
      font-weight: 520;
      font-size: medium;
    }

    hr {
      color: #60606010;
    }

    .div2equal {
      gap: 0 !important;
    }
    .pc {
      gap: 1em !important;
    }

    b {
      font-weight: 520;
    }

    h5 {
      font-size: medium;
      color: $primary;
      margin-bottom: 5px;
    }

    h6 {
      font-size: medium;
      color: $accent;
      margin: 5px;
      text-align: center;
    }

    .select {
      label {
        display: block;
        margin: 10px 0 10px 0;
        font-size: medium;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .input {
      label {
        display: block;
        margin: 10px 0 5px 0;
        font-size: small;
      }

      input {
        margin: 0px 0 10px 0;
        padding: 8px;
        font-size: small;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
      textarea {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      textarea:focus {
        border-bottom: 2px solid $primary;
      }
    }
    button {
      display: block;
      margin: 1em auto 1em auto;
      padding: 8px 2em 8px 2em;
      width: fit-content; /* Standard property */
      width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
      background: linear-gradient(
        180deg,
        $primary 0%,
        $primarylight 50%,
        $primary 100%
      );
      color: white;
      border: none;
      cursor: pointer;
    }
    button:hover {
      background: linear-gradient(
        180deg,
        $primarylight 0%,
        $primary 50%,
        $primarylight 100%
      );
    }

    .head,
    .row {
      display: grid;
      gap: 1px;
    }

    .row {
      cursor: pointer;
      margin-bottom: 2px;
    }
    .row:hover {
      box-shadow: 1px 1px 5px #60606020;
    }

    h4 {
      font-size: medium;
      color: $primary;
      background-color: #60606020;
      border-radius: 2px;
      padding: 5px;
    }
    p {
      font-size: small;
      border-radius: 2px;
      padding: 7px 5px 7px 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .popup {
    position: absolute;
    right: 0;
    top: 1em;
    bottom: 1em;
    width: 60vw;
    height: fit-content;
    background-color: white;
    border: 1px solid #60606030;
    box-shadow: 1px 1px 5px #60606010;
    border-radius: 8px 0 0 8px;
    padding: 1em;
    z-index: 9999;

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .editdata {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3);

    .cont {
      background-color: white;
      border: 1px solid #60606030;
      box-shadow: 1px 1px 5px #60606010;
      border-radius: 8px;
      padding: 1em;
      max-width: 80%;
      width: 100%;
      margin: auto;
      height: 80vh;
      overflow: auto;
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }
}

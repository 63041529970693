@import "./variables";
@import "./mediaquery";

.login {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  // background-image: url("../assets/imgs/Background.png");
  // background-position: top;
  // background-size: cover;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  font-family: "Helvetica", sans-serif;

  .card {
    width: 80%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: auto;
    box-shadow: 2px 2px 10px #60606030;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;

    .left {
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center content vertically */
      align-items: center; /* Center content horizontally */
      text-align: center;
      height: fit-content;

      h2 {
        font-size: medium;
        text-align: center;
        margin: 1em;
      }

      .user-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2em;

        .user {
          font-size: 2em;
        }
        p {
          margin-top: 1em;
        }
        .err {
          text-align: center;
          color: $secondary;
          font-size: small;
          font-weight: 400;
          height: 16px;
          line-height: 16px;
        }
      }

      .input {
        width: 80%;
        height: 100%;
        margin: 1em 0 1em 0;

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 8px 2em 8px 2em;
          width: 60%; /* Standard property */
          // width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          background: linear-gradient(
            180deg,
            $primary 0%,
            $primarylight 50%,
            $primary 100%
          );
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          box-shadow: 2px 2px 5px #60606030;

          p {
            font-size: smaller;
          }
        }

        button:hover {
          background: linear-gradient(
            180deg,
            $primarylight 0%,
            $primary 50%,
            $primarylight 100%
          );
        }
      }
    }
    .right {
      background-color: lightcyan;
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .wrap {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      padding: 1em;
      position: relative;

      .loading {
        background-color: transparent !important;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h6 {
        color: orange;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;

        h3 {
          font-size: medium;
          font-weight: 520;
          color: white;
        }
        .fa-times {
          color: orange;
          cursor: pointer;
        }
        .fa-times:hover {
          color: orangered;
        }
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }

    .wrap2 {
      max-width: 70vw;
      width: 30%;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      padding: 1em;
      position: relative;

      .loading {
        background-color: transparent !important;
      }

      hr {
        color: #60606010;
        margin: 10px 0 10px 0;
      }

      h6 {
        color: orange;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;
        

        h3 {
          font-size: medium;
          font-weight: 520;
          color: black;
        }
        .fa-times {
          color: orange;
          cursor: pointer;
        }
        .fa-times:hover {
          color: orangered;
        }
      }

      .input {
        margin: 10px 0 10px 0;
        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1em !important;
          font-size: small;
        }

        input {
          padding: 7px;
          width: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .select {
        margin: 10px 0 10px 0;
        label {
          margin-bottom: 10px;
          font-size: small;
        }

        select {
          padding: 7px;
          width: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: small;
          margin-top: 10px;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}

@import "./mediaquery";
@import "./variables";

.gis {
  position: relative;
  height: fit-content;
  padding: 1em;

  .container {
    padding: 10px;
    background-color: white;
    box-shadow: 1px 1px 5px #60606010;
    border-radius: 5px;
    height: fit-content;

    hr {
      color: #60606030;
      margin: 10px 0 10px 0;
    }

    .top {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1.5em;
      align-items: center;

      h3 {
        color: $primary;
        font-size: large;
        font-weight: 520;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 10px;
        height: fit-content;
      }

      .cats {
        display: grid;
        grid-template-columns: auto auto;
        width: fit-content;
        /* Standard property */
        width: -moz-fit-content;
        /* Firefox 3+, Firefox for Android 4+ */
        gap: 10px;
        margin: auto 0 auto 0;

        h3 {
          color: $primary;
          font-size: medium;
          font-weight: 520;
          background-color: $bg1;
          border-radius: 5px;
          cursor: pointer;
          padding: 5px 10px;
          height: fit-content;
        }

        h3:hover {
          background-color: $primarylighter;
        }

        .active {
          background-color: $primarylighter;
        }
      }

      button {
        padding: 5px 10px 5px 10px;
        cursor: pointer;
        border: none;
        background-color: $primary;
        color: white;
        font-size: x-small;
        height: fit-content;
        border-radius: 5px;
      }

      .data {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 10px;
        margin: auto 0 auto 0;

        p {
          padding: 5px 10px 5px 10px;
          border: 1px solid $primarylight;
          color: $primarylight;
          border-radius: 5px;
          cursor: pointer;
          font-size: x-small;
          height: fit-content;
        }

        .active {
          color: white;
          background-color: $primarylight;
        }
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          height: 100%;
          display: flex;
          align-items: center;
          color: #60606050;
        }

        input {
          width: 100%;
          padding: 8px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }

    .map {
      position: relative;
      height: 75vh;

      .loading {
        position: absolute !important;
        background-color: rgba($color: #000000, $alpha: 0.1);
      }

      .map-element {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606010;
      }

      .legend {
        border: 1px solid #60606030;
        border-radius: 5px;
        padding: 10px;
        overflow: hidden;
        margin-top: 10px;
        position: absolute;
        background-color: white;
        z-index: 9999;
        bottom: 2em;
        left: 10px;
        width: 100%;
        max-width: 300px;

        h4 {
          font-weight: 520;
          text-align: left;
          margin-bottom: 10px;
        }

        .list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;

          .legnd {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;

            img {
              height: 16px;
              width: 16px;
              margin: auto;
              object-fit: contain;
            }

            div {
              height: 16px;
              width: 16px;
              margin: auto;
            }
          }

          p {
            font-size: x-small;
          }
        }
      }

      .map_panel {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: $bg1;

        .outer {
          position: relative;

          .fa {
            background-color: white;
            cursor: pointer;
            border-radius: 5px;
            font-size: large;
            width: 16px;
            height: 32px;
            padding: 8px;
            color: $secondary;
          }

          .right {
            position: absolute;
            left: -32px;
            top: 0;
            border-radius: 5px 0 0 5px;
          }
        }

        .collapsible {
          min-width: 250px;

          .bar {
            background-color: $primary;
            border-radius: 0 5px 0 0;
            overflow: hidden;

            p {
              padding: 5px;
              font-size: small;
              cursor: pointer;
              color: white;
              text-align: center;
            }

            p:hover {
              color: white;
              background-color: $primarylight;
            }

            .active {
              color: white;
              background-color: $primarylight;
            }
          }

          .r_layers {
            padding: 10px;

            h4 {
              font-size: small;
              font-weight: 520;
              color: $primary;
            }

            hr {
              margin: 10px 0 10px;
              color: #60606030;
            }

            .item {
              display: grid;
              grid-template-columns: auto 1fr auto auto auto;
              gap: 10px;
              padding: 5px 0 5px 0;

              .fas {
                cursor: pointer;
              }

              .fas:hover {
                color: $secondary;
              }

              .extent {
                transform: rotate(45deg);
              }

              * {
                font-size: small;
                align-items: center;
                display: flex;
              }
            }
          }

          .query {
            padding: 10px;

            h4 {
              font-size: small;
              font-weight: 520;
              color: $primary;
            }

            hr {
              margin: 5px 0 5px;
              color: #60606010;
            }

            .list {
              height: 30vh;
              overflow-y: auto;
            }

            .add {
              display: grid;
              grid-template-columns: 1fr auto 1fr auto;
              gap: 10px;
              width: fit-content;
              /* Standard property */
              width: -moz-fit-content;
              /* Firefox 3+, Firefox for Android 4+ */

              .input {
                label {
                  display: block;
                  margin: 0px 0 5px 0;
                  font-size: x-small !important;
                }

                textarea,
                input {
                  margin: 0px 0 0 0;
                  padding: 5px;
                  max-width: 100px;
                  width: fit-content;
                  /* Standard property */
                  width: -moz-fit-content;
                  /* Firefox 3+, Firefox for Android 4+ */
                  background-color: white;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border: 1px solid #60606030;
                  box-shadow: 1px 1px 5px #60606010;
                  font-size: x-small;
                }

                textarea:focus,
                input:focus {
                  border-bottom: 1px solid $primary;
                }
              }

              .select {
                label {
                  display: block;
                  margin: 0px 0 5px 0;
                  font-size: x-small !important;
                }

                select {
                  margin: 0px 0 5px 0;
                  padding: 5px;
                  max-width: 100px;
                  width: fit-content;
                  /* Standard property */
                  width: -moz-fit-content;
                  /* Firefox 3+, Firefox for Android 4+ */
                  font-size: x-small;
                  background-color: white;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border: 1px solid #60606030;
                  box-shadow: 1px 1px 5px #60606010;
                }
              }

              .btn {
                height: 24px;
                width: 24px;
                border-radius: 5px;
                cursor: pointer;
                background-color: $primarylight;
                display: flex;
                align-items: center;
                margin: auto;
                color: white;
                padding: 5px;
              }

              .btn:hover {
                background-color: $primary;
              }
            }

            .run {
              button {
                border-radius: 5px;
                cursor: pointer;
                background-color: $primarylight;
                display: flex;
                align-items: center;
                color: white;
                padding: 5px;
                margin: 5px auto 10px auto;
                border: none;
              }

              button:hover {
                background-color: $primary;
              }

              h6 {
                font-size: small;
                color: $secondary;
                font-weight: 500;
                cursor: pointer;
                margin: auto;
                display: block;
                width: fit-content;
                /* Standard property */
                width: -moz-fit-content;
                /* Firefox 3+, Firefox for Android 4+ */
              }

              h6:hover {
                color: $primarylight;
              }
            }

            .item {
              display: grid;
              grid-template-columns: 1fr auto auto auto;
              gap: 10px;
              padding: 5px;
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606010;
              border-radius: 5px;
              margin-bottom: 5px;

              .fas {
                color: $secondary;
                display: flex;
                align-items: center;
                cursor: pointer;
              }

              .fas:hover {
                color: $primarylight;
              }

              .extent {
                transform: rotate(45deg);
              }

              * {
                font-size: small;
                align-items: center;
                display: flex;
              }
            }
          }
        }
      }

      .ol-zoom {
        position: absolute;
        top: auto;
        bottom: 44px;
        right: 10px;
        left: auto;

        * {
          cursor: pointer;
          background-color: #5889c2;
          color: white;
          font-size: medium;
        }

        *:hover {
          background-color: $accent;
        }
      }

      .ol-zoom-extent {
        position: absolute;
        top: auto;
        bottom: 14px;
        right: 10px;
        left: auto;
        width: fit-content;
        /* Standard property */
        width: -moz-fit-content;
        /* Firefox 3+, Firefox for Android 4+ */
        height: fit-content;

        * {
          cursor: pointer;
          color: white;
          background-color: #5889c2;
        }

        *:hover {
          background-color: $accent;
        }
      }

      .cpopup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.2);
        display: flex;
        align-items: center;
        z-index: 999999;
        animation: zoom 1s ease;

        .ccontainer {
          background-color: white;
          border-radius: 8px;
          padding: 10px 1em;
          margin: 0 auto 0 auto;
          color: #000000;
          max-width: 70vw;
          max-height: 75vh;
          height: fit-content;
          overflow-y: auto;
          width: fit-content;
          /* Standard property */
          width: -moz-fit-content;
          /* Firefox 3+, Firefox for Android 4+ */
          min-width: 250px;
          position: relative;

          hr {
            margin: 0 !important;
          }

          .ctop {
            display: grid;
            grid-template-columns: 1fr auto;

            h3 {
              font-size: medium;
              color: $primarylight;
              margin-bottom: 0 !important;
            }

            .fa-times {
              padding: 8px;
              height: 32px;
              width: 32px;
              cursor: pointer;
              color: $secondary;
            }

            .fa-times:hover {
              color: $primarylight;
            }
          }

          .selection {
            h4 {
              color: $primary;
              margin-bottom: 10px;
            }

            h5 {
              width: 100%;
              padding: 10px;
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              border-radius: 5px;
              cursor: pointer;
              margin-bottom: 10px;
              font-weight: 400;
            }

            h5:hover {
              box-shadow: 1px 1px 5px #60606050;
            }
          }

          .div3equal {
            gap: 0 !important;
          }

          h4 {
            font-size: small;
            font-weight: 520;
            padding: 5px;
          }

          .mlist {
            padding: 8px;

            .itt {
              border-radius: 5px;
              padding: 5px;
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              margin-bottom: 8px;
            }

            p {
              font-size: x-small;
            }
          }

          .pitem {
            display: grid;
            grid-template-columns: 1fr 2fr;

            h4 {
              height: 100%;
              display: flex;
              align-items: center;
              font-size: x-small;
              background-color: $bg1;
              padding: 3px;
              font-weight: 520;
              border: 1px solid #60606030;
              border-collapse: collapse;
            }

            p {
              font-size: x-small;
              background-color: white;
              padding: 3px;
              border: 1px solid #60606030;
              border-collapse: collapse;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }

          form {
            textarea {
              resize: none;
              width: 100%;
              margin: auto;
              padding: 4px;
              font-size: 18px;
            }
          }

          .input-map {
            margin: 10px 0 10px 0;

            h4 {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
            }

            textarea {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            textarea:focus {
              border-bottom: 2px solid $primary;
            }

            input {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          .usrselect {
            margin: 10px 0 10px 0;

            h4 {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
            }

            select {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          p {
            font-size: medium;
            margin: 0px 0 5px 0;

            b {
              color: #74465c;
              cursor: pointer;
            }
          }

          button {
            display: block;
            margin: 1em auto 1em auto;
            padding: 12px;
            width: 80%;
            background-color: $primary;
            color: white;

            border-right: none;
            border-left: none;
            border-bottom: 2px solid #60606070;
            border-top: 2px solid #60606070;
            cursor: pointer;
          }

          button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
          }
        }

        @include maxwidth(mobile) {
          .container {
            max-width: 85% !important;
          }
        }

        @include maxwidth(tablet) {
          .container {
            max-width: 85% !important;
          }
        }
      }

      .download {
        position: absolute;
        bottom: 13px;
        right: 40px;
        z-index: 9999;

        display: grid;
        grid-template-columns: repeat(2, 1fr);

        a {
          width: fit-content;
          /* Standard property */
          width: -moz-fit-content;
          /* Firefox 3+, Firefox for Android 4+ */
          color: white;
          background-color: #5889c2;
          margin: 0 0 0 5px;
          display: block;
          padding: 3px 10px 3px 10px;
          font-size: small;
          border-radius: 2px;
          cursor: pointer;
        }

        a:hover {
          background-color: #217de6;
        }

        i {
          margin-right: 5px;
          font-size: smaller;
        }

        @include maxwidth(mobile) {
          right: 54px !important;
        }

        @include maxwidth(tablet) {
          right: 54px !important;
        }
      }

      .analysis {
        color: $primary;
        position: absolute;
        max-width: -moz-fit-content;
        max-width: fit-content;
        z-index: 9999;
        top: 10px;
        bottom: 10px;
        right: 10px;
        left: auto;
        background-color: white;
        text-align: center;
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 10px;
        box-shadow: 1px 1px 5px #60606010;
        border-radius: 6px;
        overflow: hidden;

        .title {
          display: grid;
          grid-template-columns: 1.5fr 0.1fr;
          box-shadow: 1px 1px #60606020;

          h3 {
            height: 32px;
            line-height: 32px;
            color: $primary;
            font-size: small;
          }

          .fa {
            float: right;
            color: rgb(0, 128, 0);
            cursor: pointer;
          }

          .fa:hover {
            color: $secondary;
          }
        }

        .left {
          position: relative;
          padding: 1em 5px 1em 5px;
          display: grid;
          grid-template-rows: 0.5fr 0.7fr 0.7fr;
          height: 100%;

          .fa-refresh {
            color: rgb(0, 128, 0) !important;
          }

          .fa-minus {
            position: absolute;
            right: 1em;
            top: 1em;
            padding: 6px;
            cursor: pointer;
            background-color: rgb(0, 128, 0);
            border-radius: 4px;
            color: white;
          }

          .fa-minus:hover {
            background-color: $primary;
          }

          .custom-tooltip {
            background-color: rgb(0, 128, 0);
            color: white;
            padding: 2px;
            border-radius: 4px;

            * {
              font-size: small;
            }
          }

          text {
            white-space: nowrap;
            font-size: xx-small;
            text-orientation: upright;
            -webkit-text-orientation: upright;
            z-index: 999;
          }

          .l1,
          .l2,
          .l3 {
            height: 100%;
          }

          h2 {
            font-size: medium;
            text-align: center;
            margin: 0 auto 5px auto;
          }

          p {
            text-align: center;
            margin: 5px;
            font-size: small;
          }

          .pies {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
          }
        }
      }

      .visualization {
        position: absolute;
        z-index: 9999;
        left: 10px;
        top: 10px;
        width: fit-content;
        /* Standard property */
        width: -moz-fit-content;
        /* Firefox 3+, Firefox for Android 4+ */

        select {
          width: 100%;
          background-color: rgba($color: #fff, $alpha: 1);
          border-radius: 4px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: small;
          padding: 5px;
          text-align: center;
          color: $primary;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606030;
          cursor: pointer;
          display: block;
          outline: none;
          border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
            1;
        }
      }

      .analysis_hidden {
        display: none;
      }

      .expand {
        position: absolute;
        z-index: 99999;
        top: 10px;
        right: 10px;
        color: #fff;
        background-color: rgb(0, 128, 0);
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;
      }

      .expand:hover {
        background-color: $primary;
      }

      .expand_hidden {
        display: none;
      }
    }
  }

  .search_market {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 9999;
    background-color: $accent;
    padding: 8px;
    font-size: medium;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }

  .top_panel {
    position: absolute;
    left: 46px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 3px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    border: 1px solid $accent;
    width: fit-content;
    /* Standard property */
    width: -moz-fit-content;
    /* Firefox 3+, Firefox for Android 4+ */

    select {
      border: none;
      outline: none;
      padding: 4px;

      * {
        font-size: small;
      }
    }
  }

  .BottomPanel {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 0;
    height: fit-content;
    z-index: 9999;

    .opts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 5px 5px 0 0;

      h4 {
        color: white !important;
        font-weight: 200 !important;
        height: fit-content;
        text-align: center;
        padding: 8px 0 8px 0;
        cursor: pointer;
      }

      h4:hover {
        color: white !important;
        background-color: $accent;
        border-radius: 5px;
      }
    }

    .Popup {
      height: fit-content;
      z-index: 9999;
      background-color: rgba($color: white, $alpha: 0.9);
      min-height: 40vh;
      max-height: 65vh;
      border-radius: 8px;
      padding: 10px 1em 10px 1em;
      animation: zoom 3s ease;

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        /* Standard property */
        width: -moz-fit-content;
        /* Firefox 3+, Firefox for Android 4+ */
        margin-left: auto;
        cursor: pointer;
      }

      i:hover {
        color: $accent;
      }

      .items {
        width: 100%;

        .input {
          max-width: 200px;
          width: 100%;
          margin: 5px auto 5px auto;

          label {
            display: block;
          }

          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          input {
            width: 100%;
            outline: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #60606040;
            padding: 8px;
            background-color: transparent;
            color: $dark;
          }
        }

        .aoi {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          p {
            line-height: 44px;
            text-align: left !important;
          }
        }

        h3 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        h4 {
          font-size: 10pt;
          text-align: center;
          padding: 10px 0 10px 0;
        }

        img {
          height: 44px;
          width: 44px;
          object-fit: contain;
        }

        .imgSelected {
          border: 2px solid $primary;
        }

        .list {
          padding: 1em;
        }

        .item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          color: $dark;
          margin: 16px 0 16px 0;
        }
      }
    }

    @include maxwidth(mobile) {
      left: 17% !important;
      width: 63vw;
    }
  }

  .popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 999999;
    animation: zoom 1s ease;

    .container {
      background-color: white;
      border-radius: 5px;
      padding: 10px;
      margin: 0 auto 0 auto;
      color: #000000;
      max-width: 70vw;
      max-height: 70vh;
      height: fit-content;
      overflow-y: auto;
      width: 100%;
      position: relative;

      .bar {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        background-color: $accent;
        color: white;
        padding: 0 !important;

        p {
          text-align: center;
          margin: 0 !important;
          cursor: pointer;
        }

        .active {
          background-color: wheat;
          color: $accent;
        }

        p:hover {
          background-color: wheat;
          color: $accent;
        }

        @include maxwidth(mobile) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @include maxwidth(tablet) {
          grid-template-columns: repeat(3, 1fr) !important;
        }
      }

      .content {
        position: relative;
        min-height: 30vh;

        .tally {
          position: absolute;
          top: 5px;
          right: 0;

          .active {
            background-color: #74465c;
          }

          p {
            background-color: $accent;
            border-radius: 2px;
            cursor: pointer;
            width: 32px;
            height: 32px;
            text-align: center;
            color: white;
          }

          p:hover {
            background-color: #74465c;
          }
        }

        @include maxwidth(mobile) {
          display: block !important;

          p {
            word-wrap: break-word !important;
          }
        }

        @include maxwidth(tablet) {
          display: block !important;

          p {
            word-wrap: break-word !important;
          }
        }
      }

      .confirm {
        padding-top: 20px;
        text-align: center;

        p {
          width: 100%;
        }

        .success {
          color: $green;
        }

        .isError {
          color: red;
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          background-color: $green;
          color: white;

          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }

        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .newMember {
          margin: 1em auto 0 auto;
          text-decoration: none;
          font-size: larger;
          background-color: $accent;
          color: white;
          cursor: pointer;
          height: fit-content;
          display: block;
          padding: 6px;
          border-radius: 4px;
          box-shadow: 1px 1px 5px #60606030;
        }

        .newMember:hover {
          background-color: $primary;
        }
      }

      .search {
        padding-top: 10px;
        text-align: center;

        input {
          margin-top: 10px;
        }

        .search_list {
          margin-top: 2px;
          cursor: pointer;
        }
      }

      h3 {
        text-align: center;
        font-size: x-large !important;
      }

      .fa-times {
        position: absolute;
        right: 1em;
        top: 10px;
        padding: 10px;
        cursor: pointer;
        color: $primary;
      }

      .fa-times:hover {
        color: $accent;
      }

      hr {
        margin-bottom: 10px;
      }

      i {
        display: block;
        width: fit-content;
        /* Standard property */
        width: -moz-fit-content;
        /* Firefox 3+, Firefox for Android 4+ */
        padding: 5px;
        margin: 0 0 0 auto;
        color: $accent;
      }

      i:hover {
        color: $primary;
      }

      h3 {
        padding: 0px 0 10px 0;
        font-size: medium;
      }

      form {
        textarea {
          resize: none;
          width: 100%;
          margin: auto;
          padding: 4px;
          font-size: 18px;
        }
      }

      .input-map {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .usrselect {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }

        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      p {
        font-size: medium;
        margin: 0px 0 5px 0;

        b {
          color: #74465c;
          cursor: pointer;
        }
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }

      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
    }

    @include maxwidth(mobile) {
      .container {
        max-width: 85% !important;
      }
    }

    @include maxwidth(tablet) {
      .container {
        max-width: 85% !important;
      }
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }

      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .cont {
    padding: 2em;

    .input-map {
      label {
        display: block;
        font-size: medium;
        color: $dark;
        margin: 1em 0 10px 0;
      }

      input {
        width: 100%;
        padding: 1em;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        box-shadow: 1px 1px 5px #60606030;
        border: 1px solid rgba($color: $dark, $alpha: 0.5);
        margin: 1em 0 0 0;
      }
    }

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }

      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }

  @include maxwidth(mobile) {
    .legend {
      max-width: 200px !important;
      bottom: 2em !important;
    }

    .container {
      .top {
        grid-template-columns: 1fr;

        .filter {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .declutter {
      left: 30% !important;
    }
  }

  @include maxwidth(tablet) {
    .declutter {
      left: 30% !important;
    }

    .container {
      .top {
        grid-template-columns: auto;
        gap: 1em;
      }
    }
  }

  .zoom_out {
    animation: zoomout 1s ease;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoomout {
  0% {
    top: 0;
    opacity: 1;
  }

  100% {
    top: -300px;
    opacity: 0;
  }
}

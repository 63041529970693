$primary: #002F6C;
$primarylight: #0064B6;
$white: #ffffff;
$primarylighter: #A7C6ED;
$secondary: #BA0C2F;
$accent: #8C8C8C;
$bg: #F0F0EF;
$bg1: #F0F0EF;
$line: #F0F0EF;
$dark: #231F20;
$green: #04AE3E;

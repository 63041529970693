@import "./variables";
@import "./mediaquery";

.image-slider {
  width: 100%;
  height: 100%; /* Adjust as needed */
  overflow: hidden;
  position: relative;
}

.image-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  right: 100%; /* Initially position the image out of view */
  transition: right 5s ease-in-out;
}

.image-slide.active {
  right: 0;
}
